import { get } from 'lodash';
import {
  getCardBorderWidthPath,
  isLayoutTextOnImage,
  LayoutType,
  Section,
} from '@wix/communities-blog-client-common';
import { useSelector } from '../components/runtime-context';
import { getAppSettings } from '../selectors/app-settings-base-selectors';
import useLayoutProps, { UseLayoutPropsParams } from './use-layout-props';

export type UseCardBorderWidthOwnProps = UseLayoutPropsParams;

const useCardBorderWidth = (props?: UseCardBorderWidthOwnProps) => {
  const layoutProps = useLayoutProps(props);
  const borderWidth = useSelector((state) => {
    const isTextOnImage = isLayoutTextOnImage(
      layoutProps.layoutType as LayoutType,
    );

    if (isTextOnImage) {
      return 0;
    }

    const appSettings = getAppSettings(state);
    const cardBorderWidthPath = getCardBorderWidthPath(
      layoutProps.layoutType as LayoutType,
      layoutProps.section as Section,
    );
    const defaultWidth = 1;

    return get(appSettings, cardBorderWidthPath, defaultWidth) as number;
  });

  return {
    ...layoutProps,
    borderWidth,
  };
};

export default useCardBorderWidth;
