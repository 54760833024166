import React from 'react';

export function withInvalidState(Component) {
  return (props) => {
    if (!props.state) {
      // this error will be caught by sentry error boundary
      throw new Error(
        'Controller not initialized. Cannot render without props.',
      );
    }

    return <Component {...props} />;
  };
}
