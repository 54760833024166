import type { AppState } from '../types';

const getRouter = (state: AppState) => state.router;
export const getCurrentMatch = (state: AppState) =>
  getRouter(state)?.currentMatch;
export const getPreviousMatch = (state: AppState) =>
  getRouter(state)?.previousMatches?.[1] ?? {};
export const getCurrentMatchPathname = (state: AppState) =>
  getRouter(state)?.currentMatch?.pathname ?? '';
export const getCurrentMatchEncodedPathname = (state: AppState) =>
  getRouter(state)?.currentMatch?.encodedPathname ?? '';
export const getIsInitialRoute = (state: AppState) =>
  getRouter(state)?.previousMatches.length === 0;
export const getRoute = (state: AppState) => getCurrentMatch(state)?.route;
export const getRouteParams = (state: AppState) =>
  getCurrentMatch(state)?.params;
