export const getLocalizedYearAndMonth = (utcDate, timeZone) => {
  const date = new Date(utcDate);
  if (!timeZone) {
    return { month: date.getMonth() + 1, year: date.getFullYear() };
  }
  const [month, year] = date
    .toLocaleString('en', { timeZone, year: 'numeric', month: 'numeric' })
    .split('/');
  return { month: parseInt(month, 10), year: parseInt(year, 10) };
};
