import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import withFontClassName, {
  type WithFontClassName,
} from '../../hoc/with-font-class-name';
import { usePostBorderWidth } from '../../hooks/use-post-border-width';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import styles from './empty-states.scss';

type OwnProps = {
  title?: string;
  content?: string;
};

type EmptyStatesProps = {
  children?: ReactNode;
  className?: string;
  style?: object;
  isLayoutTextOnImage?: boolean;
  titleClassName?: string;
} & WithFontClassName &
  OwnProps;

const EmptyStates = ({
  title,
  content,
  style = {},
  className,
  children,
  titleFontClassName,
  contentFontClassName,
  isLayoutTextOnImage = false,
  titleClassName,
}: EmptyStatesProps) => {
  const { borderWidth } = usePostBorderWidth();
  const { getPostClassName } = useIsFeedDesignEnabled();

  const containerClassName = classNames(
    styles.container,
    contentFontClassName,
    className,
    isLayoutTextOnImage
      ? styles.isLayoutTextOnImage
      : ['blog-card-background-color', 'blog-text-color'],
    'blog-card-border-color',
    getPostClassName?.('border-color', 'post-container', 'background-color'),
  );

  const titleContainerClassName = classNames(
    titleFontClassName,
    titleClassName,
    getPostClassName?.('title-font', 'title-color'),
  );

  const contentContainerClassName = classNames(
    styles.content,
    getPostClassName?.('description-font', 'description-color'),
  );

  return (
    <div
      data-hook="empty-state-container"
      className={containerClassName}
      style={{ borderWidth, ...style }}
    >
      <div className={titleContainerClassName} data-hook="empty-states__title">
        <div tabIndex={0} className={styles.header}>
          {title}
        </div>
      </div>
      <div className={contentContainerClassName}>
        <div className={styles.description}>{content}</div>
      </div>
      {children && <div className={styles.controls}>{children}</div>}
    </div>
  );
};

export default flowRight(
  withFontClassName,
)(EmptyStates) as React.FC<OwnProps>;
