import { get, includes } from 'lodash';
import {
  ROUTE_HOME,
  ROUTE_CATEGORY,
  ROUTE_CATEGORIES,
  ROUTE_SEARCH,
  ROUTE_TAGS,
  ROUTE_ARCHIVE,
  ROUTE_HASHTAG,
} from '../../feed-page/constants/routes';
import {
  ROUTE_POST,
  ROUTE_POST_ADVANCED_SLUG,
  ROUTE_PREVIEW_POST,
  ROUTE_CREATE_POST,
  ROUTE_EDIT_POST,
  ROUTE_404,
  ROUTE_EDIT_COMMENT,
  ROUTE_EDIT_COMMENT_ADVANCED_SLUG,
} from '../../post-page/constants/routes';

const PAGE_NOT_FOUND = 'page_not_found';
export const SEARCH_RESULTS = 'search_results';
export const POST_PAGE = 'post_page';
const POST_PAGE_PREVIEW = 'post_page_preview';
export const POST_PAGE_NOT_FOUND = 'post_page_not_found';
export const POST_EDIT_PAGE = 'post_edit_page';
const COMMENT_EDIT_PAGE = 'comment_edit_page';
export const FEED_PAGE = 'feed_page';
export const FEED_PAGE_NOT_FOUND = 'feed_page_not_found';
export const CATEGORY_PAGE = 'categories_page';
const CREATE_POST = 'create_post';
export const ARCHIVE_PAGE = 'archive_page';
export const HASHTAG_PAGE = 'hashtag_page';
export const TAGS_PAGE = 'tags_page';

const routeNameMap = {
  [PAGE_NOT_FOUND]: [ROUTE_404],
  [FEED_PAGE]: [ROUTE_HOME],
  [SEARCH_RESULTS]: [ROUTE_SEARCH],
  [CREATE_POST]: [ROUTE_CREATE_POST],
  [CATEGORY_PAGE]: [ROUTE_CATEGORY, ROUTE_CATEGORIES],
  [COMMENT_EDIT_PAGE]: [ROUTE_EDIT_COMMENT, ROUTE_EDIT_COMMENT_ADVANCED_SLUG],
  [POST_EDIT_PAGE]: [ROUTE_EDIT_POST],
  [POST_PAGE_PREVIEW]: [ROUTE_PREVIEW_POST],
  [POST_PAGE]: [ROUTE_POST, ROUTE_POST_ADVANCED_SLUG],
  [ARCHIVE_PAGE]: [ROUTE_ARCHIVE],
};

const routeNameMapEntries = Object.entries(routeNameMap);

export const getRouteName = (route) => {
  const matchingEntry = routeNameMapEntries.find(([, routes]) =>
    includes(routes, route),
  );
  return get(matchingEntry, '[0]');
};

export const isInFeed = (route) => route === ROUTE_HOME;
export const isInCategory = (route) =>
  includes([ROUTE_CATEGORY, ROUTE_CATEGORIES], route);
export const isInTags = (route) => route === ROUTE_TAGS;
export const isInRouteUsingFeedPage = (route) =>
  includes(
    [
      ROUTE_CATEGORY,
      ROUTE_CATEGORIES,
      ROUTE_HOME,
      ROUTE_TAGS,
      ROUTE_ARCHIVE,
      ROUTE_HASHTAG,
    ],
    route,
  );
export const isInPostPage = (route) =>
  includes([ROUTE_POST, ROUTE_POST_ADVANCED_SLUG], route);
export const isInPostPreview = (route) => route === ROUTE_PREVIEW_POST;
export const isInPostEditor = (route) =>
  includes([ROUTE_CREATE_POST, ROUTE_EDIT_POST], route);
export const isInArchivePage = (route) => route === ROUTE_ARCHIVE;
export const isInHashtagPage = (route) => route === ROUTE_HASHTAG;
