import { get } from 'lodash';

const getPostCounters = (state, postId) => state.postCounters[postId];

export const getViewCount = (state, postId) =>
  get(getPostCounters(state, postId), 'viewCount', 0);
export const getViewCountFormatted = (state, postId) =>
  get(getPostCounters(state, postId), 'viewCountFormatted');
export const getCommentCount = (state, postId) =>
  get(getPostCounters(state, postId), 'totalComments', 0);
export const getCommentCountFormatted = (state, postId) =>
  get(getPostCounters(state, postId), 'totalCommentsFormatted');
