import { flowRight } from 'lodash';
import { connect } from '../components/runtime-context';

import {
  getFontSize,
  getDefaultFontSizeDesktop,
} from '../selectors/app-settings-selectors';
import { getLayoutType } from '../selectors/layout-selectors';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import withHocName from './with-hoc-name';
import withLayoutProps from './with-layout-props';

const mapRuntimeToProps = (state, { section }) => {
  const isMobile = getIsMobile(state);
  const layoutType = getLayoutType(state, section, isMobile);
  return {
    postTitleFontSize: getFontSize(state, layoutType, section, 'title'),
    postDescriptionFontSize: getFontSize(
      state,
      layoutType,
      section,
      'description',
    ),
    postMetadataFontSize: getFontSize(state, layoutType, section, 'metadata'),
    defaultPostTitleFontSize: getDefaultFontSizeDesktop(
      state,
      layoutType,
      section,
      'title',
    ),
    defaultPostDescriptionFontSize: getDefaultFontSizeDesktop(
      state,
      layoutType,
      section,
      'description',
    ),
  };
};

export default flowRight(
  withLayoutProps(),
  connect(mapRuntimeToProps),
  withHocName('WithPostFontSize'),
);
