import { get } from 'lodash';

export const getPostLikes = (state, postId) =>
  state.postLikes[postId] || { isLiked: false, likeCount: 0 };
export const getIsLiked = (state, postId) =>
  get(getPostLikes(state, postId), 'isLiked', false);
export const getIsLikeInProgress = (state, postId) =>
  get(getPostLikes(state, postId), 'isLikeInProgress', false);
export const getLikeCount = (state, postId) =>
  get(getPostLikes(state, postId), 'likeCount', 0);
export const getLikeCountFormatted = (state, postId) =>
  get(getPostLikes(state, postId), 'likeCountFormatted');
