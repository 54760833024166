import { getLayoutType } from '../../../selectors/layout-selectors';
import { isDebug } from '../../../store/basic-params/basic-params-selectors';
import {
  CONSTANTS,
  DEFAULT_CATEGORY_LABEL_LINE_HEIGHT,
  DEFAULT_DESCRIPTION_LINE_HEIGHT,
  DEFAULT_TITLE_LINE_HEIGHT,
} from './constants';
import {
  getCategoryLabelSize,
  getDescriptionSize,
  getFooterSize,
  getHeaderSize,
  getTitleSize,
} from './selectors';

export const calculateContentHeight = (state, section, passedLayoutType) => {
  const layoutType = passedLayoutType || getLayoutType(state, section, false);
  const constants = CONSTANTS[layoutType];

  const categoryLabelSize = getCategoryLabelSize(state, constants);
  const header = getHeaderSize(state, constants);
  const footer = getFooterSize(state, constants);
  const title = getTitleSize(state, section, layoutType, constants);
  const description = getDescriptionSize(state, section, layoutType, constants);

  const totalHeight = Math.ceil(
    header +
      footer +
      title +
      description +
      categoryLabelSize +
      constants.VERTICAL_PADDING,
  );

  if (isDebug(state)) {
    console.groupCollapsed('blog auto height');
    console.log('Constants');
    console.table({
      ...constants,
      DEFAULT_TITLE_LINE_HEIGHT,
      DEFAULT_DESCRIPTION_LINE_HEIGHT,
      DEFAULT_CATEGORY_LABEL_LINE_HEIGHT,
    });
    console.log('Calculated components');
    console.table({
      header,
      footer,
      title,
      description,
      categoryLabelSize,
      verticalPadding: constants.VERTICAL_PADDING,
      total: totalHeight,
    });
    console.groupEnd();
  }

  return totalHeight;
};
