import {
  SECTION_HOMEPAGE,
  isLayoutNameTextOnImage,
  getLayoutName,
} from '@wix/communities-blog-client-common';
import { useSelector } from '../components/runtime-context';
import { getAppSettingsNumber } from '../selectors/app-settings-base-selectors';
import { getUseMobileDesignSettings } from '../selectors/app-settings-selectors';
import { getLayoutType } from '../selectors/layout-selectors';
import { getSection } from '../selectors/section-selectors';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import useCardBorderWidth from './use-card-border-width';
import useIsFeedDesignEnabled from './use-is-feed-design-enabled';

type UseFeedBorderWidthParams = Parameters<typeof useCardBorderWidth>[0];

export function useFeedBorderWidth(params?: UseFeedBorderWidthParams) {
  const { borderWidth: cardBorderWidth } = useCardBorderWidth(params);
  const { applyFeedDesign } = useIsFeedDesignEnabled(params?.section);
  return useSelector((state) => {
    const section = getSection(state) || SECTION_HOMEPAGE;
    const layoutName = getLayoutName(
      getLayoutType(state, section, getIsMobile(state))!,
    );
    const useMobileStyle = getUseMobileDesignSettings(state);
    const feedBorderWidth = isLayoutNameTextOnImage(layoutName)
      ? 0
      : getAppSettingsNumber(
          state,
          useMobileStyle
            ? `post-${section}-mobile-borderWidth`
            : `post-${section}-borderWidth`,
        );

    return { borderWidth: applyFeedDesign ? feedBorderWidth : cardBorderWidth };
  });
}
