const PG_EVENTS = {
  ITEM_CLICKED: 'ITEM_CLICKED',
} as const;

const CLICK_TARGET_OPTIONS = {
  ITEM_MEDIA: 'item-media',
  ITEM_INFO: 'item-info',
  ITEM_CONTAINER: 'item-container',
} as const;

type Values<T> = T[keyof T];
type ClickTarget = Values<typeof CLICK_TARGET_OPTIONS>;

export const isClickTargetItemMedia = (eventData: {
  clickTarget?: ClickTarget;
}) => eventData?.clickTarget === CLICK_TARGET_OPTIONS.ITEM_MEDIA;

export const isPGEventItemClicked = (eventName: string) =>
  eventName === PG_EVENTS.ITEM_CLICKED;
