import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { resolveId } from '@wix/communities-blog-client-common';

import LikeButtonWithCount from '../like-button-with-count';
import PostStats from '../post-stats';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import useLayoutColorClasses from '../../hooks/use-layout-color-classes';
import useFontClassName from '../../hooks/use-font-class-name';
import useAuth from '../../hooks/use-auth';
import styles from './post-footer.scss';

const PostFooter = ({
  post,
  displayIcons,
  type,
  className,
  onLikeClick,
}) => {
  const {
    showViewCount,
    showCommentCount,
    showLikeCount,
   } = useFeedMetadataSettings({});
  const { commentClassName, textColorClassName } = useLayoutColorClasses();
  const { contentFontClassName } = useFontClassName();
  const { forPublicUser } = useAuth();

  const handleLikeClick = () => onLikeClick(resolveId(post));

  return (
    <div
      data-hook="post-footer"
      className={classNames(
        styles.container,
        styles[type],
        contentFontClassName,
        textColorClassName,
        'post-footer',
        className,
      )}
    >
      {(showViewCount || showCommentCount) && (
        <div className={styles.leftButtons}>
          <PostStats
            commentClassName={commentClassName}
            post={post}
            displayIcons={displayIcons}
            showViewCount={showViewCount}
            showCommentCount={showCommentCount}
          />
        </div>
      )}
      {showLikeCount && (
        <div className={styles.rightButtons}>
          <LikeButtonWithCount
            onClick={forPublicUser(handleLikeClick)}
            entity={post}
            className="post-footer__like-button"
          />
        </div>
      )}
    </div>
  );
}

PostFooter.propTypes = {
  post: PropTypes.object.isRequired,
  displayIcons: PropTypes.bool,
  onLikeClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default PostFooter;
