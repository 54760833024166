import React from 'react';
import { SECTION_BLOG_POST_PAGE } from '@wix/communities-blog-client-common';
import BaseLink from './base-link';

type Props = {
  postLink: string;
  postPath: string;
} & Omit<React.ComponentProps<typeof BaseLink>, 'href' | 'path' | 'sectionId'>;

const PostLink: React.FC<Props> = ({
  postLink,
  postPath,
  children,
  ...rest
}) => {
  return (
    <BaseLink
      {...rest}
      href={postLink}
      path={postPath}
      sectionId={SECTION_BLOG_POST_PAGE}
    >
      {children}
    </BaseLink>
  );
};

export default PostLink;
