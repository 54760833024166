import {
  AUTHOR_INFO_TYPE_NAME,
  hasTPACommentsSettings,
  getTPASettingsIsCommentsEnabled,
  Section,
} from '@wix/communities-blog-client-common';
import { useSelector } from '../components/runtime-context';
import {
  getIsViewCountEnabled,
  getIsCommentCountEnabled,
  getIsLikeCountEnabled,
  getIsAuthorNameEnabled,
  getIsPostUpdatedDateEnabled,
  getIsCommentsEnabled,
  getIsRecentPostsEnabled,
  getIsCategoryLabelsEnabled,
  getIsPostTitleEnabled,
  getIsPostPublishDateEnabled,
  getIsReadingTimeEnabled,
  getIsPostDescriptionEnabled,
  getAuthorInfoType,
  getIsAuthorPictureEnabled,
  getIsMoreOptionsMenuEnabled,
  getIsRelatedPostsEnabled,
  getRelatedPostsLabel,
  getIsCategoryLabelEnabled,
  getIsPostRatingEnabled,
} from '../selectors/app-settings-selectors';
import { getIsPostInPreview } from '../selectors/is-post-in-preview';
import { getMainCategory } from '../store/categories/categories-selectors';
import { NormalizedPost } from '../types';

export type UseFeedMetadataSettings = {
  showComments: boolean;
  showRecentPosts: boolean;
  showViewCount: boolean;
  showCommentCount: boolean;
  showLikeCount: boolean;
  showAuthorName: boolean;
  showAuthorPicture: boolean;
  authorInfoType: 0 | 1;
  showPostUpdatedDate: boolean;
  showCategoryLabels: boolean;
  showPostTitle: boolean;
  showPublishDate: boolean;
  showReadingTime: boolean;
  showPostDescription: boolean;
  showMoreOptionsMenu: boolean;
  showCategoryLabel: boolean;
  isMetadataFooterVisible: boolean;
  isMetadataHeaderVisible: boolean;
  showRelatedPosts: boolean;
  relatedPostsLabelKey: string | undefined;
  showPostRating: boolean;
};

type UseFeedMetadataSettingsParams = {
  post: NormalizedPost;
  section?: Section;
  hideAuthorBadge?: boolean;
};

export const useFeedMetadataSettings = (
  params: UseFeedMetadataSettingsParams,
): UseFeedMetadataSettings => {
  return useSelector((state) => {
    const isPostInPreview = getIsPostInPreview(state);
    const isCommentsEnabled = isPostInPreview
      ? false
      : hasTPACommentsSettings(state)
      ? getTPASettingsIsCommentsEnabled(state)
      : getIsCommentsEnabled(state);
    const isRecentPostsEnabled =
      !isPostInPreview && getIsRecentPostsEnabled(state);
    const isRelatedPostsEnabled =
      !isPostInPreview && getIsRelatedPostsEnabled(state);
    const relatedPostsLabelKey = !isPostInPreview
      ? getRelatedPostsLabel(state)
      : undefined;
    const showViewCount = !isPostInPreview && getIsViewCountEnabled(state);
    const showCommentCount =
      isCommentsEnabled && getIsCommentCountEnabled(state);
    const showLikeCount = !isPostInPreview && getIsLikeCountEnabled(state);
    const showAuthorName = getIsAuthorNameEnabled(state);
    const authorInfoType = getAuthorInfoType(state);
    const showPostUpdatedDate = getIsPostUpdatedDateEnabled(state);
    const showCategoryLabels = getIsCategoryLabelsEnabled(state);
    const showPostTitle = getIsPostTitleEnabled(state);
    const showPublishDate = getIsPostPublishDateEnabled(state);
    const showReadingTime = getIsReadingTimeEnabled(state);
    const showPostDescription = getIsPostDescriptionEnabled(
      state,
      params.section,
    );
    const oldShowAuthorPictureValue = authorInfoType !== AUTHOR_INFO_TYPE_NAME;
    const showAuthorPicture = getIsAuthorPictureEnabled(
      state,
      oldShowAuthorPictureValue,
    );
    const showMoreOptionsMenu = getIsMoreOptionsMenuEnabled(state);
    const showCategoryLabel = getMainCategory(state, params.post)
      ? getIsCategoryLabelEnabled(state)
      : false;
    const showPostRating = getIsPostRatingEnabled(state);

    return {
      showComments: isCommentsEnabled,
      showRecentPosts: isRecentPostsEnabled,
      showViewCount,
      showCommentCount,
      showLikeCount,
      showAuthorName,
      showAuthorPicture,
      authorInfoType,
      showPostUpdatedDate,
      showCategoryLabels,
      showPostTitle,
      showPublishDate,
      showReadingTime,
      showPostDescription,
      showMoreOptionsMenu,
      showCategoryLabel,
      isMetadataFooterVisible:
        !isPostInPreview &&
        (showViewCount || showCommentCount || showLikeCount),
      isMetadataHeaderVisible:
        showAuthorName || showPublishDate || showReadingTime,
      showRelatedPosts: isRelatedPostsEnabled,
      relatedPostsLabelKey,
      showPostRating,
    };
  });
};
