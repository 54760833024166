import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

type Props = React.PropsWithChildren<{
  minutes?: number;
  isShortLabel?: boolean;
  className?: string;
}>;

const TimeToRead: React.FC<Props> = ({
  minutes,
  isShortLabel,
  children,
  ...rest
}) => {
  const { t } = useTranslation();

  if (typeof minutes !== 'number') {
    return null;
  }

  const text = t(
    isShortLabel ? 'time-to-read.short-label' : 'time-to-read.label',
    { minutes },
  );

  return (
    <span {...rest} title={text} data-hook="time-to-read">
      {text}
    </span>
  );
};

export default TimeToRead;
