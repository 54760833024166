import { chain } from 'lodash';
import {
  PostAction,
  POST_ACTIONS,
  POST_ACTION_SHARE,
  POST_ACTION_SUBSCRIBE,
} from '@wix/communities-blog-client-common';
import { NormalizedPost } from '../types';

export const getPostActions = ({
  actions = POST_ACTIONS,
  post,
  canSee,
  enableShare = true,
  enableSubscribe = true,
}: {
  actions?: PostAction[] | readonly PostAction[];
  post: NormalizedPost;
  canSee: (action: PostAction, type: 'post', post: NormalizedPost) => boolean;
  enableShare?: boolean;
  enableSubscribe?: boolean;
}): PostAction[] =>
  chain(actions)
    .filter((action) => canSee(action, 'post', post))
    .without(
      // @ts-expect-error
      enableShare || POST_ACTION_SHARE,
      enableSubscribe || POST_ACTION_SUBSCRIBE,
    )
    .value();
