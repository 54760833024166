import React from 'react';
import classNames from 'classnames';
import { isLayoutList, resolveId } from '@wix/communities-blog-client-common';
import { getLayoutConfig } from '../../services/layout-config';
import PostListItemProGallery from '../post-list-item-pro-gallery';
import styles from './create-info-renderer.scss';

export const createInfoRenderer =
  ({
    allPosts,
    prevAllPosts,
    layoutType,
    layoutName,
    section,
    layoutSidesPadding,
    canSeeMoreButton,
    hideAuthorBadge,
    textBoxAlignment,
    options,
    onLikeClick,
    getPostClassName,
    visibleActions,
  }) =>
  (itemProps, placement) => {
    if (isLayoutList(layoutType) && placement === 'SHOW_BELOW') {
      const containerClassName = classNames(
        getPostClassName('background-color'),
        styles.listLayoutFullHeightBackground,
      );
      return <div className={containerClassName}></div>;
    }

    const { itemConfig } = getLayoutConfig(layoutType);
    const currentPost =
      allPosts.find((post) => resolveId(post) === itemProps.id) ||
      prevAllPosts.find((post) => resolveId(post) === itemProps.id);

    return (
      <PostListItemProGallery
        itemConfig={itemConfig}
        type={layoutName}
        postId={itemProps.id}
        post={currentPost}
        onLikeClick={onLikeClick}
        galleryOptions={options}
        layoutSidesPadding={layoutSidesPadding}
        section={section}
        canSeeMoreButton={canSeeMoreButton}
        hideAuthorBadge={hideAuthorBadge}
        textBoxAlignment={textBoxAlignment}
        visibleActions={visibleActions}
      />
    );
  };
