const createLikeCountMessage = ({ t, likeCount }) => {
  if (!likeCount) {
    return null;
  }

  return `${likeCount} ${
    likeCount > 1
      ? t('like-button.total-likes_plural')
      : t('like-button.total-likes')
  }`;
};

const createLikedStatusMessage = ({ t, isComment, isLiked }) => {
  if (isLiked) {
    return isComment
      ? t('like-button.comment-is-liked')
      : t('like-button.is-liked');
  }

  return isComment
    ? t('like-button.comment-is-unliked')
    : t('like-button.is-unliked');
};

export const createLikeButtonMessage = ({
  t,
  isLiked,
  likeCount,
  isComment,
}) => {
  const likesCountMessage = createLikeCountMessage({ t, likeCount });
  const likedStatusMessage = createLikedStatusMessage({
    t,
    isComment,
    isLiked,
  });

  return likesCountMessage
    ? `${likesCountMessage} ${likedStatusMessage}`
    : likedStatusMessage;
};
