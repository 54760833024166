import React from 'react';
import classNames from 'classnames';
import {
  MixedLayoutTypeName,
  PostAction,
} from '@wix/communities-blog-client-common';
import AuthorWithPostDetails from '../../../post-page/components/author-with-post-details';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import { NormalizedPost } from '../../types';
import Avatar from '../avatar';
import ProfileLink from '../link/profile-link';
import PostListItemHeaderOptions from './post-list-item-header-options';
import styles from './post-list-item-header.scss';

type Props = {
  className?: string;
  moreButtonClass?: string;
  moreButtonIcon?: React.ReactNode;
  post: NormalizedPost;
  hideAuthorBadge?: boolean;
  showHeaderIcons?: boolean;
  showMoreButton?: boolean;
  showProfileImage?: boolean;
  style?: React.CSSProperties;
  type: MixedLayoutTypeName;
  visibleActions?: PostAction[];
};

const PostListItemHeader: React.FC<Props> = ({
  className,
  moreButtonClass,
  moreButtonIcon,
  post,
  hideAuthorBadge,
  showHeaderIcons = true,
  showMoreButton = true,
  showProfileImage = true,
  style,
  type,
  visibleActions,
}) => {
  const {
    showAuthorName,
    showAuthorPicture,
    showPublishDate,
    isMetadataHeaderVisible,
    showCategoryLabel,
    showReadingTime,
  } = useFeedMetadataSettings({ post, hideAuthorBadge });
  const withoutHeaderMetadata = !isMetadataHeaderVisible;
  const containerClassName = classNames(
    styles.container,
    styles[type],
    'blog-post-metadata-font',
    'post-header',
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    className,
    showCategoryLabel && styles.withCategoryLabel,
    showAuthorName &&
      (showPublishDate || showReadingTime || showAuthorPicture) &&
      styles.spacingBottom,
  );

  return (
    <div className={containerClassName} style={style}>
      <div
        className={classNames([
          styles.metadata,
          showMoreButton && styles.withThreeDots,
          alignmentStyles.metadataContainer,
        ])}
      >
        {isMetadataHeaderVisible && (
          <>
            {showAuthorName && showProfileImage && showAuthorPicture && (
              <div className={alignmentStyles.avatar}>
                <ProfileLink user={post.owner}>
                  <Avatar user={post.owner} isNameVisible={false} />
                </ProfileLink>
              </div>
            )}
            <AuthorWithPostDetails
              post={post}
              type={type}
              className={alignmentStyles.postDetails}
              showAuthorName={showAuthorName}
              showPublishDate={showPublishDate}
              showReadingTime={showReadingTime}
            />
          </>
        )}
      </div>
      {(showHeaderIcons || showMoreButton) && (
        <PostListItemHeaderOptions
          moreButtonClass={moreButtonClass}
          moreButtonIcon={moreButtonIcon}
          post={post}
          showHeaderIcons={showHeaderIcons}
          showMoreButton={showMoreButton}
          type={type}
          visibleActions={visibleActions}
        />
      )}
    </div>
  );
};

export default PostListItemHeader;
