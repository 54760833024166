import {
  ROUTE_PREFIX_ARCHIVE,
  ROUTE_PREFIX_CATEGORIES,
  ROUTE_PREFIX_HASHTAGS,
  ROUTE_PREFIX_TAGS,
} from '@wix/communities-blog-client-common';

export const ROUTE_404 = '/404';

export const ROUTE_HOME = '/(page/:page(/))';
export type RouteHomeParams = {
  page?: string;
};

export const ROUTE_CATEGORY =
  '/category/:categorySlug(/:subcategorySlug)(/page/:page)(/)';
export const ROUTE_CATEGORIES = `/${ROUTE_PREFIX_CATEGORIES}/:categorySlug(/:subcategorySlug)(/page/:page)(/)`;
export type RouteCategoryParams = {
  categorySlug: string;
  subcategorySlug?: string;
  page?: string;
};

export const ROUTE_SEARCH = '/search(/:query)';
export type RouteSearchParams = {
  query?: string;
};

export const ROUTE_HASHTAG = `/${ROUTE_PREFIX_HASHTAGS}/:hashtag(/page/:pageIndex)(/)`;
export type RouteHashtagParams = {
  hashtag: string;
  pageIndex?: string;
};

export const ROUTE_ARCHIVE = `/${ROUTE_PREFIX_ARCHIVE}/:year/:month(/page/:pageIndex)(/)`;
export type RouteArchiveParams = {
  year: string;
  month: string;
  pageIndex?: string;
};

export const ROUTE_TAGS = `/${ROUTE_PREFIX_TAGS}/:tagSlug(/page/:page)(/)`;
export type RouteTagsParams = {
  tagSlug: string;
  page?: string;
};

export const ROUTE_LOGIN = '/login';
