import React from 'react';
import classNames from 'classnames';
import TimeAgo from '../../../common/components/time-ago';
import TimeToRead from '../../../common/components/time-to-read';
import PostMetadataLayout from '../post-metadata-layout';
import styles from './post-metadata.scss';

interface Props {
  date?: Date | string | number;
  dateFormatted?: string;
  readTime?: number;
  isShortReadTimeLabel?: boolean;
  className?: string;
  showPublishDate?: boolean;
  showReadingTime?: boolean;
}

const PostMetadata: React.FC<Props> = ({
  className,
  date,
  dateFormatted,
  readTime,
  isShortReadTimeLabel,
  showPublishDate,
  showReadingTime,
}) => {
  return (
    <PostMetadataLayout
      className={classNames(className, styles.container)}
      itemClassName={styles.item}
    >
      {showPublishDate && (
        <TimeAgo
          className="post-metadata__date"
          time={date}
          timeFormatted={dateFormatted}
        />
      )}
      {showReadingTime && (
        <TimeToRead
          className="post-metadata__readTime"
          minutes={readTime}
          isShortLabel={isShortReadTimeLabel}
        />
      )}
    </PostMetadataLayout>
  );
};

export default PostMetadata;
