export const loadTranslations = async ({
  baseUrl,
  translationsName,
  language,
  request,
}) =>
  request(`/${translationsName}_${language}.json`, {
    baseUrl,
    dismissHeaders: true,
    dismissParams: true,
  });
