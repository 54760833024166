import { get, isEmpty } from 'lodash';
import {
  isExperimentEnabled,
  logMetrics,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_COLLECT_METRICS } from '@wix/communities-blog-experiments';
import {
  isDebug,
  isSSR,
} from '../../store/basic-params/basic-params-selectors';

const INITIAL_SSR_VERSION = 0;
const INITIAL_CSR_VERSION = 1;

const getStateVersions = (previousState, currentState, previousVersions) => {
  const currentVersions = Object.entries(currentState).reduce(
    (versions, [key, value]) => {
      versions[key] =
        get(previousVersions, key, 1) + (previousState[key] === value ? 0 : 1);
      return versions;
    },
    {},
  );

  return currentVersions;
};

const getStateVersionsDiff = (previousVersions, currentVersions) =>
  Object.entries(currentVersions)
    .filter(([key, value]) => value !== previousVersions[key])
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

export const getInitialStateVersions = (state) => {
  const value = isSSR(state) ? INITIAL_SSR_VERSION : INITIAL_CSR_VERSION;
  return Object.keys(state).reduce((versions, key) => {
    versions[key] = value;
    return versions;
  }, {});
};

export const getUpdatedState = ({
  currentState,
  currentVersions,
  newState,
  newVersions,
  currentControllerId,
  newControllerId,
}) => {
  if (newControllerId < currentControllerId) {
    return currentState;
  }

  if (
    !currentVersions ||
    !newVersions ||
    currentControllerId !== newControllerId
  ) {
    return newState;
  }

  const updatedState = Object.keys(currentVersions).reduce(
    (state, key) => {
      if (currentVersions[key] === newVersions[key]) {
        state[key] = currentState[key];
      }
      return state;
    },
    { ...newState },
  );

  return updatedState;
};

export function subscribeToChange(
  store,
  stateVersions,
  setProps,
  controllerId,
) {
  let previousState = store.getState();
  let previousVersions = stateVersions;

  store.subscribe(() => {
    const currentState = store.getState();
    const currentVersions = getStateVersions(
      previousState,
      currentState,
      previousVersions,
    );
    const versionsDiff = getStateVersionsDiff(
      previousVersions,
      currentVersions,
    );

    if (isEmpty(versionsDiff)) {
      isDebug(currentState) &&
        console.log(
          'action had been dispatched, but it did not update the state',
        );
      return;
    }

    if (isDebug(currentState)) {
      console.log('state versions updated', versionsDiff);
      isExperimentEnabled(currentState, EXPERIMENT_COLLECT_METRICS) &&
        logMetrics();
    }

    previousState = currentState;
    previousVersions = currentVersions;

    return setProps({
      state: currentState,
      stateVersions: currentVersions,
      controllerId,
    });
  });
}
