import { get, last, toNumber } from 'lodash';
import { createSelector } from 'reselect';

import {
  MAX_POSTS_PER_PAGE,
  MAX_POSTS_PER_PAGE_FULL_POST,
  MAX_POSTS_PER_PAGE_MOBILE,
  PaginationType,
  POSTS_PER_PAGE,
  getPaginationTypeParamPath,
  getPaginationPageSizeParamPath,
  isMultiplePagesSupported,
  getLastPage,
  buildPaginationRequestParams,
  Section,
} from '@wix/communities-blog-client-common';
import { getIsInitialRoute } from '../router/router-selectors';
import {
  getIsMobile,
  isSeo,
  isSite,
} from '../store/basic-params/basic-params-selectors';
import { AppState } from '../types';
import { getAppSettingsValue } from './app-settings-base-selectors';
import { isFullPostLayout, getLayoutType } from './layout-selectors';

import { getSection } from './section-selectors';

type EntityPagination<T = any> = {
  entitiesByPage: { [page: string]: T[] };
  entityCount: number;
  currentPage: number;
  pageSize: number;
  cursor: string;
};

const getPagination = (state: {
  pagination: Record<string, EntityPagination>;
}) => state.pagination;

const getEntityPagination = createSelector(
  [getPagination, (_, entityType: string) => entityType],
  (pagination, entityType) =>
    get(pagination, entityType, {} as EntityPagination),
);

export const getEntitiesByPage = createSelector(
  [getEntityPagination],
  (entityPagination) => entityPagination.entitiesByPage,
);

export const getEntityCount = createSelector(
  [getEntityPagination],
  (entityPagination) => entityPagination.entityCount,
);

export const getCurrentPage = createSelector(
  [getEntityPagination],
  (entityPagination) => entityPagination.currentPage || 1,
);

export const getCurrentPageEntities = createSelector(
  [getEntitiesByPage, getCurrentPage],
  (entitiesByPage, page) => get(entitiesByPage, page),
);

export const getLastPageIndex = createSelector(
  [getEntitiesByPage],
  (entitiesByPage) => toNumber(last(Object.keys(entitiesByPage).sort())) || 1,
);

export const getPageSize = (
  state: AppState,
  {
    overrideSettingsPageSize = undefined,
    section,
  }: {
    section?: Section;
    overrideSettingsPageSize?: number;
  } = {},
) => {
  let maxPageSize: number = MAX_POSTS_PER_PAGE;

  if (
    getIsMobile(state) &&
    getPaginationType(state, section) === PaginationType.InfiniteScroll &&
    !isSeo(state)
  ) {
    maxPageSize = MAX_POSTS_PER_PAGE_MOBILE;
  } else if (isFullPostLayout(state, section)) {
    maxPageSize = MAX_POSTS_PER_PAGE_FULL_POST;
  }
  return Math.min(
    overrideSettingsPageSize || getPaginationPageSize(state, section),
    maxPageSize,
  );
};

export const getPaginationType = (state: AppState, section?: Section) => {
  section ||= getSection(state, true);
  const paginationType = getAppSettingsValue({
    state,
    key: getPaginationTypeParamPath(section),
    fallback: PaginationType.InfiniteScroll,
  });
  const layoutType = getLayoutType(state, section);
  if (
    paginationType === PaginationType.MultiplePages &&
    isMultiplePagesSupported(layoutType)
  ) {
    return paginationType;
  }
  return PaginationType.InfiniteScroll;
};

export const getPaginationPageSize = (state: AppState, _section?: Section) => {
  const section = _section || getSection(state, true);
  return getAppSettingsValue({
    state,
    key: getPaginationPageSizeParamPath(section),
    fallback: POSTS_PER_PAGE,
  });
};

export const getShowPagination = (state: AppState, section: Section) => {
  const paginationType = getPaginationType(state, section);
  return paginationType === PaginationType.MultiplePages;
};

export const getIsValidPage = (state: AppState, section: Section) => {
  const paginationType = getPaginationType(state, section);
  const page =
    paginationType === PaginationType.MultiplePages
      ? getCurrentPage(state, 'posts')
      : getLastPageIndex(state, 'posts');

  const pageSize = getPaginationPageSize(state, section);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);
  return page === 1 || (page > 1 && page <= lastPage);
};

export const getIsLazyPaginationEnabled = (
  state: AppState,
  section?: Section,
) => {
  section ??= getSection(state, true);

  return (
    !isSeo(state) &&
    isSite(state) &&
    getIsMobile(state) &&
    getShowPagination(state, section) &&
    getPageSize(state, { section }) > MAX_POSTS_PER_PAGE_MOBILE
  );
};

export const getLazyPaginationParams = ({
  state,
  page,
  section,
}: {
  state: AppState;
  page: number;
  section: Section;
}) => {
  if (
    !getIsLazyPaginationEnabled(state, section) ||
    !getIsInitialRoute(state)
  ) {
    return;
  }

  const pageSize = getPageSize(state, { section });
  const paginationParams = buildPaginationRequestParams(page, pageSize);
  if (page === 1) {
    paginationParams.size = MAX_POSTS_PER_PAGE_MOBILE;
  }
  return paginationParams;
};
