import React from 'react';
import { omit } from 'lodash';

const handleEventNoop: React.MouseEventHandler = (ev) => {
  ev.stopPropagation();
  ev.preventDefault();
};

const NoopLink: React.FC<any> = ({ children, ...props }) => {
  const elementProps = omit(props, ['user', 'tReady', 'lng']);
  return (
    <span
      style={{ pointerEvents: 'none' }}
      {...elementProps}
      onClick={handleEventNoop}
    >
      {children}
    </span>
  );
};

export default NoopLink;
