import { mapValues } from 'lodash';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { connect } from '../components/runtime-context';

const mapRuntimeToProps = (config) => (state) =>
  mapValues(config, (path) => isExperimentEnabled(state, path));

/** @deprecated Use `useSelector` / `connect` together with `isExperimentEnabled */
export default function withExperiment(config) {
  return (WrappedComponent) =>
    connect(mapRuntimeToProps(config))(WrappedComponent);
}
