import React, { useEffect, useRef } from 'react';
import { SSR_SUCCESS } from '../../constants/interactions';
import { getHasReportedAppLoad } from '../../store/app-loaded/app-loaded-selectors';
import {
  getIsForumLoadedOnInitialPage,
  isDebug as getIsDebug,
  isProduction as getIsProduction,
  isSSR as getIsSSR,
} from '../../store/basic-params/basic-params-selectors';
import { useActions, useSelector } from '../runtime-context';

const shouldReportSSRSuccess = () => {
  return (
    typeof window !== 'undefined' &&
    !window.clientSideRender &&
    // @ts-expect-error
    window.sssr?.success
  );
};

export const AppLoaded: React.FC = () => {
  const isSSR = useSelector(getIsSSR);
  const host = useSelector((_state, hostProps) => hostProps);
  const hasReported = useSelector(getHasReportedAppLoad);
  const isDebug = useSelector(getIsDebug);
  const isProduction = useSelector(getIsProduction);
  const isForumLoadedOnInitialPage = useSelector(getIsForumLoadedOnInitialPage);
  const actions = useActions();

  const applicationLoaded = () => {
    const shouldLog = isDebug && !isProduction;

    if (shouldLog) {
      console.log('applicationLoaded');
    }

    // if forum is first page to be loaded (means it was SSR), then we report track SSR.
    // if it was successful then on initial hydration there will be SSR true on state.
    if (isForumLoadedOnInitialPage) {
      actions.interactionStarted(SSR_SUCCESS);
      if (shouldReportSSRSuccess()) {
        if (shouldLog) {
          console.log('Report SSR success');
        }
        actions.interactionEnded(SSR_SUCCESS);
      }
    }
    host.appLoadBI.loaded();

    requestAnimationFrame(() => {
      actions.appLoaded();
    });
  };

  const timeoutRef = useRef<any>();

  useEffect(() => {
    // we check if it's not SSR, because if it is true, then it means this is initial hydration with SSR props and there is no controller yet
    if (!isSSR && !hasReported && !timeoutRef.current) {
      timeoutRef.current = setTimeout(() => applicationLoaded()); // Ensures that masonry is rendered
      if (typeof performance !== 'undefined') {
        performance.mark && performance.mark('communities-app-loaded');
      }
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  });

  return hasReported ? (
    <div data-hook="blog-loaded-indicator" style={{ display: 'none' }} />
  ) : null;
};

export default AppLoaded;
