import { get } from 'lodash';
import { CARD_BORDER_WIDTH_PATH } from '@wix/communities-blog-client-common';
import { useSelector } from '../components/runtime-context';
import { getAppSettings } from '../selectors/app-settings-base-selectors';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import { useFeedBorderWidth } from './use-feed-border-width';
import useIsFeedDesignEnabled from './use-is-feed-design-enabled';

type UsePostBorderWidthParams = Parameters<typeof useFeedBorderWidth>[0];

export function usePostBorderWidth(params?: UsePostBorderWidthParams) {
  const { borderWidth: feedBorderWidth } = useFeedBorderWidth(params);
  const { applyFeedDesign } = useIsFeedDesignEnabled(params?.section);

  return useSelector((state) => {
    const getPostCardBorderWidth = () => {
      const DEFAULT_WIDTH = 1;

      return getIsMobile(state)
        ? DEFAULT_WIDTH
        : get(getAppSettings(state), CARD_BORDER_WIDTH_PATH, DEFAULT_WIDTH);
    };

    return {
      borderWidth: applyFeedDesign ? feedBorderWidth : getPostCardBorderWidth(),
    };
  });
}
