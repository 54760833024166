import { get } from 'lodash';
import {
  IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH,
  IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH,
  SECTION_RELATED_POSTS,
  getLayoutTypePath,
  isLayoutPGOneColumn,
  isLayoutPGSideBySide,
  relatedPostsDisplayParamsByDevice,
  relatedPostsWidgetStyleParams,
  Layout,
} from '@wix/communities-blog-client-common';
import {
  getAppSettings,
  getAppSettingsValue,
} from '../../common/selectors/app-settings-base-selectors';
import { getDefaultLayout } from '../../common/selectors/layout-selectors';
import { getIsMobileSettingEnabled } from '../../common/selectors/mobile-settings-selectors';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { AppState } from '../../common/types';

const getUseMobileDisplaySettings = (state: AppState) =>
  getIsMobile(state) &&
  getIsMobileSettingEnabled(state, IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH);

export const getRelatedPostCount = (state: AppState) => {
  return getAppSettingsValue({
    state,
    key: `style.numbers.${relatedPostsWidgetStyleParams['post-list-postCount'].key}`,
    fallback: relatedPostsWidgetStyleParams['post-list-postCount'].defaultValue,
  });
};

export const getIsSeeAllLinkEnabled = (state: AppState) => {
  const param = getUseMobileDisplaySettings(state)
    ? relatedPostsDisplayParamsByDevice.mobile
    : relatedPostsDisplayParamsByDevice.desktop;

  return getAppSettingsValue({
    state,
    key: `style.booleans.${param.seeAllLinkEnabled.key}`,
    fallback: param.seeAllLinkEnabled.defaultValue,
  });
};

export const getIsWidgetTitleEnabled = (state: AppState) => {
  const param = getUseMobileDisplaySettings(state)
    ? relatedPostsDisplayParamsByDevice.mobile
    : relatedPostsDisplayParamsByDevice.desktop;

  return getAppSettingsValue({
    state,
    key: `style.booleans.${param.widgetTitleEnabled.key}`,
    fallback: param.widgetTitleEnabled.defaultValue,
  });
};

export const getRelatedPostsLayoutType = ({
  state,
  isMobile,
}: {
  state: AppState;
  isMobile?: boolean;
}): Layout => {
  const desktopLayout =
    getLayout(state, false) ?? getDefaultLayout(state, false);
  const mobileLayout = getIsMobileSettingEnabled(
    state,
    IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH,
  )
    ? getLayout(state, true)
    : undefined;

  if (isMobile && !mobileLayout) {
    return Layout.List;
  }

  const layoutType = (isMobile && mobileLayout) || desktopLayout;

  if (
    isMobile &&
    (isLayoutPGSideBySide(layoutType) || isLayoutPGOneColumn(layoutType))
  ) {
    return Layout.PgGrid;
  }

  return layoutType;
};

function getLayout(state: AppState, isMobile: boolean) {
  return get(
    getAppSettings(state),
    getLayoutTypePath(SECTION_RELATED_POSTS, isMobile),
  ) as Layout | undefined;
}
