import React from 'react';
import createHocName from '../services/create-hoc-name';

const withHocName =
  (name: string) =>
  <T = any>(Component: React.FC<T>) => {
    Component.displayName = createHocName(name, Component);
    return Component;
  };

export default withHocName;
