import { flowRight } from 'lodash';
import { ConnectedProps } from 'react-redux';
import { getIsPostPageBundle } from '@wix/communities-blog-client-common';
import { connect } from '../components/runtime-context';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type useFontClassName from '../hooks/use-font-class-name';

import withHocName from './with-hoc-name';

export type WithFontClassName = ConnectedProps<typeof connector>;

const connector = connect(
  (state) =>
    ({
      titleFontClassName: `blog-post-title-font`,
      contentFontClassName: getIsPostPageBundle(state)
        ? `blog-post-page-font`
        : `blog-post-description-font`,
      contentFontClassNameWithStyle: `blog-post-description-style-font`,
    } as const),
);

/** @deprecated use {@link useFontClassName} hook instead */
const withFontClassName = flowRight(
  connector,
  withHocName('WithFontClassName'),
);

export default withFontClassName;
