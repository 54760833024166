import { createAction } from '@wix/communities-blog-client-common';

export const SET_IS_LOADING = 'isLoading/SET';

export const setIsLoading = createAction(
  SET_IS_LOADING,
  (entity, id, isLoading) => ({
    entity,
    id,
    isLoading,
  }),
);
